.post-wrapper {
  display: flex;
  flex-direction: column;
  p {
    min-height: 20px !important;
    font-size: 16px !important;
    background-color: transparent !important;
    span {
      font-size: 16px !important;
      background-color: transparent !important;
    }
  }
}