.wrapper-class {
  background-color: white;
  padding-inline: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.fontSizeDropdown {
  z-index: 10;
}
.rdw-dropdown-carettoopen {
  z-index: 1;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
}
.public-DraftStyleDefault-block {
  margin: 0px;
}